<template>
  <div>
    <v-card class=" mt-3 ml-2 mr-2">
      <v-card-subtitle>
        <span class="blue--text text-h6"> Şirket Ekle </span>
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <div class="col-md-12">
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            dense
            @click="createFarm"
          >
            <v-icon>mdi-plus</v-icon>

            Şirket Ekle
          </v-btn>
        </div>
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page.sync="itemPerPage"
            class="elevation-1"
            dense
          >
            <template v-slot:item.status="{item}">
              <Chips
                :payload="{
                  color: item.status === 'Active' ? 'green' : 'red',
                  text: item.status === 'Active' ? 'Aktif' : 'Pasif',
                  textColor: 'white',
                  small: true
                }"
              />
            </template>
            <template v-slot:item.companyCode="{item}">
              <v-icon
                class="mr-2"
                outlined
                @click="editItemOpen(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                outlined
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>

              <!--   <v-btn
                color="blue darken-1"
                text
                dense

                outlined
                @click="sahaAdd(item)"
              >
                Saha
              </v-btn> -->
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>

    <Dialog
      v-model="dialog"
      :persistent="true"
      max-width="500px"
    >
      <Card>
        <v-card-title>
          <span class="blue--text"> <h2>Şirket Ekle</h2></span>
        </v-card-title>
        <v-card-text>
          <v-col cols="row">
            <!--Şirket Adı:-->
            <v-text-field
              ref="companyName"
              v-model="company.companyName"
              label="Şirket Adı:"
              class="col-12 mb-0 mt-0 pt-0 pb-0"
              outlined
              dense
              hidden-footer
              hidden-details
              hidden-hint
              required
            />

            <!--Telefon:-->
            <v-text-field
              ref="adress"
              v-model="company.Phone"
              label="Telefon"
              class="col-12 mb-0 mt-0 pt-0 pb-0"
              outlined
              dense
              hidden-footer
              hidden-details
              hidden-hint
              required
            />

            <!--Adres:-->
            <v-text-field
              ref="adress"
              v-model="company.adress"
              label="Adres"
              class="col-12 mb-0 mt-0 pt-0 pb-0"
              outlined
              dense
              hidden-footer
              hidden-details
              hidden-hint
              required
            />

            <!--Durum-->
            <v-select
              v-model="company.status"
              label="Durum"
              dense
              class="col-12 ml-0 mb-0 mt-0 pt-0 pb-0"
              attach=""
              outlined
              :items="status"
            />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <Button
            color="blue darken-1"
            :text="true"
            :dense="true"
            :outlined="true"

            @click="dialog = false"
          >
            Kapat
          </Button>
          <Button
            v-if="farmEdit === 'false'"
            color="blue darken-1"
            :text="true"
            :dense="true"
            :outlined="true"
            @click="setfunc"
          >
            Kayıt
          </Button>

          <Button
            v-if="farmEdit === 'true'"
            color="blue darken-1"
            :text="true"
            :dense="true"
            :outlined="true"
            @click="editFarm"
          >
            Güncelle
          </Button>
        </v-card-actions>
      </Card>
    </Dialog>
    <Snackbar
      :notify="notify"
    />
  </div>
</template>
<script>

import {
  setCompanyCreate,
  setCompanyEdit,
  getCompanyList1,
  getCompanyDel,
} from '@/api/Request/company';
import helper from '@/api/helper';

export default {

  data: () => ({
    company: {
      companyName: '',
      adminCompanyCode: '',
      adress: '',
      status: '',
    },
    notify: {
      snackbar: false,
      text: '',
      vertical: true,
      color: 'success',
    },
    farmEdit: 'false',
    dialog: false,
    itemPerPage: 15,
    adminCompanyCode: '',
    desserts: [],
  }),
  computed: {
    headers() {
      return [
        { text: 'Şiket Adı', value: 'companyName' },
        { text: 'Adres', value: 'adress' },
        { text: 'Durum', value: 'status' },
        { text: 'Tarih', value: 'createTime' },
        { text: '', value: 'companyCode' },
      ];
    },
    status() {
      return [
        { text: 'Aktif', value: 'Active' },
        { text: 'Pasif', value: 'Passive' },
      ];
    },
  },
  created() {},
  mounted() {
    const storage = window.localStorage;
    // eslint-disable-next-line no-underscore-dangle
    const _token = helper.parseJwt(storage.getItem('vuex_user'));
    this.adminCompanyCode = _token.data.adminCompanyCode;
    this.getCompany();
  },
  methods: {
    setStatus(item) {
      return item === 'Active' ? 'Aktif' : 'Pasif';
    },
    getCompany() {
      const params = {};

      getCompanyList1(params).then((res) => {
        this.desserts = res.data.data;
      });
    },
    deleteItem(item) {
      const params = {
        condiniton: {
          companyCode: item.companyCode,
        },
      };
      getCompanyDel(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = 'Şirket silinemedi';
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = 'Şirket silindi';
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        this.getCompany();
      });
    },

    setfunc() {
      const params = { data: this.company };
      setCompanyCreate(params).then((res) => {
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = 'Şirket oluştururken hata oldu. ';
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = 'Şirket oluşturuldu. ';
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        this.getCompany();
      });
    },
    createFarm() {
      this.company = {
        companyName: '',
        adminCompanyCode: this.adminCompanyCode,
        adress: '',
        status: '',
      };
      this.dialog = true;
      this.farmEdit = 'false';
    },
    editItemOpen(item) {
      this.company = item;
      this.farmEdit = 'true';
      this.dialog = true;
    },
    sahaAdd(item) {
      this.$router.push(`/farm/lists/${item.companyCode}`);
    },
    editFarm() {
      const params = {
        condiniton: {
          companyCode: this.company.companyCode,
        },
        data: this.company,
      };

      setCompanyEdit(params).then((res) => {
        // console.log(res);
        if (res.data.success === 'false') {
          this.notify.snackbar = true;
          this.notify.text = 'Şirket bilgisi güncellenemedi';
          this.notify.vertical = true;
          this.notify.color = 'red';
        }
        if (res.data.success === 'true') {
          this.notify.snackbar = true;
          this.notify.text = 'Şirket bilgisi güncellendi. ';
          this.notify.vertical = true;
          this.notify.color = 'success';
        }
        this.getCompany();
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
